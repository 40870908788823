import { useEffect, useState } from 'react';

export const useSessionStorage = <T>(key: string, initialValue?: T) => {
    const [storedValue, setStoredValue] = useState<T | null>(() => {
        try {
            const item = sessionStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue || null;
        } catch (error) {
            console.error('Error reading from sessionStorage', error);
            return initialValue || null;
        }
    });

    const setValue = (value: T) => {
        try {
            setStoredValue(value);
            sessionStorage.setItem(key, JSON.stringify(value));
        } catch (error) {
            console.error('Error writing to sessionStorage', error);
        }
    };

    return [storedValue, setValue] as const;
};
