import { toFormData } from '../lib/utils/forms';
import { axiosAPI } from './axios-instance';
import { ApiResponse } from './lib/types';

export type ApiStocksRequest = {
    name: string;
    email: string;
    phone: number;
    company: string;

    //для АМО
    url?: string;
    utm_source?: string;
    utm_medium?: string;
    utm_campaign?: string;
    utm_term?: string;
    utm_content?: string;
};

export type ApiStocksResponse = ApiResponse<{
    message: string;
}>;

export type AppStocksResponse = ApiResponse<{
    message: string;
}>;

const transformApiResponse = (response: ApiStocksResponse): AppStocksResponse => {
    return response;
};

export const postStocks = (body: ApiStocksRequest): Promise<AppStocksResponse> =>
    axiosAPI
        .post<ApiStocksResponse>('/v1/feedback/banner', toFormData(body))
        .then((res) => transformApiResponse(res.data));
