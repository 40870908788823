import CloseSVG from '@public/icons/close-btn.svg';
import { AxiosError } from 'axios';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { AnimatePresence, m as motion } from 'framer-motion';
import { FocusEventHandler, useEffect, useRef, useState } from 'react';
import { IMaskInput } from 'react-imask';
import { AnyObject, StringSchema, addMethod, boolean, object, string } from 'yup';
import { postStocks } from '@/shared/api/post-stocks';
import { useSetOpenedPopupsState } from '@/shared/atoms/opened-popups';
import Button from '@/shared/components/shared/Button';
import Checkbox from '@/shared/components/shared/Checkbox';
import Input from '@/shared/components/shared/Input';
import Popup from '@/shared/components/shared/Popup';
import Typografed from '@/shared/components/shared/Typografed';
import { useGetCurrentUrl } from '@/shared/hooks/use-get-url';
import { useSessionStorage } from '@/shared/hooks/use-session-storage';
import { useGetUtmParams } from '@/shared/hooks/use-utm/use-get-utm';
import { FeedbackResponse } from '@/shared/lib/types';
import { tp } from '@/shared/lib/typograf';
import { formatPhoneHref } from '@/shared/lib/utils/strings';
import { isPhone } from '@/shared/lib/utils/validation/phone';

export const STOCKS_FORM_POPUP = 'stocks-form-popup';
const TIME_DURATION = 3000;
addMethod(string, 'customPhone', function (errorMessage) {
    return this.test(`test-custom-phone`, errorMessage, function (value) {
        return typeof value === 'string' ? isPhone(value) : false;
    });
});

function StocksFormPopup({ data = {} }) {
    const getUtmParams = useGetUtmParams();
    const currentUrl = useGetCurrentUrl();
    const timeoutRef = useRef<NodeJS.Timeout>();
    const [response, setResponse] = useState<FeedbackResponse | null>(null);
    const formRef = useRef<HTMLFormElement>(null);
    const { closePopup } = useSetOpenedPopupsState();
    const { openPopup } = useSetOpenedPopupsState();

    const [stocksData, setStocksData] = useSessionStorage<boolean>('stocks-data');

    useEffect(() => {
        if (!stocksData) {
            setTimeout(() => openPopup(STOCKS_FORM_POPUP), TIME_DURATION);
        }
    }, [stocksData]);

    useEffect(() => {
        if (response) {
            setTimeout(() => closePopup(STOCKS_FORM_POPUP), TIME_DURATION);
        }
    }, [response]);

    const schema = object({
        name: string().required('Поле обязательно к заполнению'),
        company: string().required('Поле обязательно к заполнению'),
        email: string()
            .required('Поле обязательно к заполнению')
            .matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, 'Введите корректный адрес эл. почты'),
        phone: (
            string().required('Поле обязательно к заполнению') as StringSchema<string, AnyObject, undefined, ''> & {
                customPhone: any;
            }
        ).customPhone('Номер телефона введён некорректно'),
        agreementChecked: boolean().oneOf([true]),
    });

    const formik = useFormik({
        initialValues: {
            ...data,
            name: '',
            company: '',
            email: '',
            phone: '',
            agreementChecked: false,
        },
        validationSchema: schema,
        onSubmit: async (values) => {
            clearTimeout(timeoutRef.current);
            setResponse(null);

            const transformedData = {
                name: values.name,
                email: values.email,
                company: values.company,
                phone: Number(formatPhoneHref(values.phone).replace('+', '')),

                url: currentUrl.length > 0 ? currentUrl : '',
                ...Object.fromEntries(Object.entries(getUtmParams).filter(([_, value]) => value)),
            };
            try {
                const data = await postStocks(transformedData);

                setResponse(data);

                if (data.status === 'success') {
                    setValues(initialValues);
                    setTouched(initialTouched);
                    setStocksData(true);
                } else {
                    setResponse({
                        status: 'error',
                        message: data.errors?.flat().join('<br>') ?? 'Что-то пошло не так',
                    });
                }
            } catch (err) {
                if (err instanceof AxiosError) {
                    setResponse({
                        status: 'error',
                        message: err.response?.data.errors?.flat().join('<br>') ?? 'Что-то пошло не так',
                    });
                } else {
                    throw err;
                }
            } finally {
                timeoutRef.current = setTimeout(() => {
                    setResponse(null);
                }, 5000);
            }
        },
    });

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
        isSubmitting,
        setFieldTouched,
        setValues,
        setTouched,
        initialTouched,
        initialValues,
        setFieldValue,
    } = formik;

    const onFocus: FocusEventHandler<HTMLInputElement> = (event) => {
        setFieldTouched(event.target.name, false);
    };

    return (
        <Popup name={STOCKS_FORM_POPUP} data-popup-preset="default" className="stocks-form-popup" overlay={true}>
            <button
                aria-label="Закрыть"
                className="menu-popup-close-btn"
                onClick={() => {
                    closePopup(STOCKS_FORM_POPUP);
                }}
            >
                <CloseSVG />
            </button>
            <div className="stocks-form-popup__container">
                <div className="stocks-form-popup__top">
                    <h2 className="stocks-form-popup-title text-xl">
                        <Typografed>Участие в TNF с выгодой до 50%</Typografed>
                    </h2>
                    <div className="stocks-form-popup__description text-s">
                        <Typografed>
                            {
                                'Эксклюзивные тарифы на корпоративное участие.  Специальные условия для делегаций от 3 человек.'
                            }
                        </Typografed>
                    </div>
                </div>
                <div className="stocks-form-popup__bottom">
                    <form ref={formRef} className="stocks-form-popup-form" noValidate onSubmit={handleSubmit}>
                        <div className="stocks-inputs-block">
                            <div className="organize-input-block stocks-input-block text-s">
                                <div className="stocks-input-name text-s">Имя *</div>
                                <Input
                                    label=""
                                    type="text"
                                    name="name"
                                    className="organize-form-popup-input"
                                    id="stocks-form-name"
                                    placeholder="Иван"
                                    value={values.name}
                                    onInput={handleChange}
                                    onChange={handleChange}
                                    onFocus={onFocus}
                                    onBlur={handleBlur}
                                    message={touched.name ? errors.name : ''}
                                    valid={!(touched.name && errors.name)}
                                />
                            </div>

                            <div className="organize-input-block stocks-input-block text-s">
                                <div className="stocks-input-name text-s">Email *</div>
                                <Input
                                    type="email"
                                    name="email"
                                    className="organize-form-popup-input"
                                    placeholder={'tnf2024@gmail.com'}
                                    required
                                    label=""
                                    disabled={isSubmitting}
                                    id="stocks-form-email"
                                    value={values.email}
                                    onInput={handleChange}
                                    onChange={handleChange}
                                    onFocus={onFocus}
                                    onBlur={handleBlur}
                                    message={touched.email ? errors.email : ''}
                                    valid={!(!!touched.email && !!errors.email)}
                                />
                            </div>
                            <div className="organize-input-block stocks-input-block text-s">
                                <div className="stocks-input-name text-s">Телефон *</div>
                                <IMaskInput
                                    id="stocks-form-phone"
                                    mask="+7 (000) 000-00-00"
                                    lazy={false}
                                    placeholder="+7 (999) 999-99-99"
                                    value={values.phone}
                                    onAccept={(value: string) => setFieldValue('phone', value)}
                                    onBlur={handleBlur}
                                    onFocus={onFocus}
                                    name="phone"
                                    className={classNames('organize-form-popup-input', {
                                        'is-error': touched.phone && errors.phone,
                                    })}
                                />
                                <div className="app-message text-xs" aria-live="polite">
                                    {touched.phone ? errors.phone : ''}
                                </div>
                            </div>

                            <div className="organize-input-block stocks-input-block text-s">
                                <div className="stocks-input-name text-s">Название компании *</div>
                                <Input
                                    label=""
                                    type="text"
                                    name="company"
                                    id="stocks-form-company"
                                    className="organize-form-popup-input"
                                    placeholder="TNF / 7743013902"
                                    value={values.company}
                                    onInput={handleChange}
                                    onChange={handleChange}
                                    onFocus={onFocus}
                                    onBlur={handleBlur}
                                    message={touched.company ? errors.company : ''}
                                    valid={!(touched.company && errors.company)}
                                />
                            </div>
                        </div>
                        <div className="stocks-form-popup-privacy">
                            <div className="form-checkbox-wrapper">
                                <Checkbox
                                    className="agreement-checkbox"
                                    name="agreementChecked"
                                    checked={values.agreementChecked}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    valid={!errors.agreementChecked}
                                >
                                    <span className="contact-us__checkbox-block__text text-xs">
                                        Нажимая кнопку, Вы даёте согласие с{' '}
                                        <a
                                            href="/privacy"
                                            className="form-checkbox-link link link--underlined"
                                            target="_blank"
                                            rel="noreferrer nofollow"
                                        >
                                            политикой конфиденциальности
                                        </a>
                                        <pre />
                                        и&nbsp;принимаете &nbsp;
                                        <a
                                            href="/privacy"
                                            className="form-checkbox-link link link--underlined"
                                            target="_blank"
                                            rel="noreferrer nofollow"
                                        >
                                            пользовательское соглашение
                                        </a>
                                    </span>
                                </Checkbox>
                                <p className="privacy-checkbox"></p>
                            </div>
                            <Button
                                type="submit"
                                variant="outline-dark"
                                disabled={isSubmitting}
                                className="stocks-form-popup-submit"
                            >
                                Отправить
                            </Button>
                        </div>
                        <AnimatePresence>
                            {response && (
                                <motion.div
                                    className="organize-detail-popup-response"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                >
                                    {response.status === 'success' ? (
                                        <>
                                            <p className="text-l">
                                                <Typografed>Форма успешно отправлена</Typografed>
                                            </p>
                                            {response.message && (
                                                <p
                                                    className="text-s"
                                                    dangerouslySetInnerHTML={{ __html: tp(response.message) }}
                                                ></p>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <p className="text-l">Произошла ошибка</p>
                                            {response.message && (
                                                <div
                                                    className="text-s stocks-form-error-message"
                                                    dangerouslySetInnerHTML={{ __html: tp(response.message) }}
                                                ></div>
                                            )}
                                            <>
                                                <Button
                                                    type="button"
                                                    variant="primary-filled"
                                                    onClick={() => {
                                                        setResponse(null);
                                                    }}
                                                >
                                                    Отправить снова
                                                </Button>
                                                <a
                                                    href={'https://t.me/TnfChatBot'}
                                                    className="text-xs"
                                                    target="_blank"
                                                    rel="noreferrer nofollow"
                                                >
                                                    Служба поддержки TNF
                                                </a>
                                            </>
                                        </>
                                    )}
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </form>
                </div>
            </div>
        </Popup>
    );
}

export default StocksFormPopup;
