import { AnimatePresence } from 'framer-motion';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { ReactNode, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import 'swiper/scss';
import '@/application/css/app.scss';
import { Providers } from '@/application/lib/providers';
import { STORAGE_SCROLL_POSITION } from '@/consts';
import { usePageTransitionValue } from '@/shared/atoms/page-transition';
import AppHead from '@/shared/components/general/AppHead';
import AppInits from '@/shared/components/general/AppInits';
import CookiesAgreement from '@/shared/components/general/CookiesAgreement';
import Footer from '@/shared/components/general/Footer';
import Header from '@/shared/components/general/Header';
import Metrics from '@/shared/components/general/Metrics';
import TransitionBlocks from '@/shared/components/general/TransitionBlocks';
import MenuPopup from '@/shared/components/popups/MenuPopup';
import SearchPopup from '@/shared/components/popups/SearchPopup/SearchPopup';
import StocksFormPopup from '@/shared/components/popups/StocksFormPopup';
import ErrorMessage from '@/shared/components/shared/ErrorMessage';
import { usePrevious } from '@/shared/hooks/use-previous';
import { CommonPageProps } from '@/shared/lib/types';
import { calculateScrollbarWidth } from '@/shared/lib/utils/calculate-scrollbar-width';
import { deleteGetParams } from '@/shared/lib/utils/strings';
import vhMobileFix from '@/shared/lib/utils/vh-mobile-fix';

if (typeof window !== 'undefined') {
    document.documentElement.classList.add('js-ready');
    vhMobileFix();
    calculateScrollbarWidth();
}

const LayoutGrid = dynamic(() => import('@/shared/components/utils/LayoutGrid'), { ssr: false });

const AnimatedPage = ({ pageProps, children }: { pageProps: CommonPageProps; children: ReactNode }) => {
    const prevBodyClass = usePrevious(pageProps.bodyClass);
    const { mode } = usePageTransitionValue();

    return (
        <AnimatePresence
            mode={mode}
            onExitComplete={() => {
                if (prevBodyClass) {
                    document.documentElement.classList.remove(...prevBodyClass.split(' '));
                }

                if (pageProps.bodyClass) {
                    document.documentElement.classList.add(...pageProps.bodyClass.split(' '));
                }

                const scrollPosition = sessionStorage.getItem(STORAGE_SCROLL_POSITION);
                window.scroll({ top: scrollPosition ? parseFloat(scrollPosition) : 0, behavior: 'auto' });
                sessionStorage.removeItem(STORAGE_SCROLL_POSITION);

                requestAnimationFrame(() => {
                    document.dispatchEvent(new Event('new-page-ready'));
                });
            }}
        >
            {children}
        </AnimatePresence>
    );
};

const App = ({ Component, pageProps, router }: AppProps<CommonPageProps>) => {
    useEffect(() => {
        history.scrollRestoration = 'manual';
    }, []);

    return (
        <Providers>
            <AppInits />
            <AppHead meta={pageProps.meta} />
            <Header />
            <main className="main">
                <ErrorBoundary
                    fallback={
                        <ErrorMessage>Произошла ошибка на странице. Мы уже занимается исправлением.</ErrorMessage>
                    }
                >
                    <AnimatedPage pageProps={pageProps}>
                        <Component
                            {...pageProps}
                            key={deleteGetParams(
                                router.asPath
                                    .replace(/\/filter\/.*\/apply/, '')
                                    .replace(router.asPath.match(/\/filter\/.*\/apply/) ? '' : /\/filter\/.*/, '')
                                    .replace('/filter/apply/', '/'),
                            )}
                        />
                    </AnimatedPage>
                </ErrorBoundary>
            </main>
            <Footer footerData={pageProps.footerData} />
            <TransitionBlocks />
            <StocksFormPopup />
            <MenuPopup
                socList={pageProps.footerData.socialLinks ?? []}
                email={pageProps.footerData.email || null}
                phone={pageProps.footerData.phone || null}
            />

            <SearchPopup />
            {process.env.NODE_ENV === 'development' && <LayoutGrid />}
            <CookiesAgreement />
            {process.env.NODE_ENV === 'production' && process.env.NEXT_PUBLIC_ENV === 'production' && <Metrics />}
        </Providers>
    );
};

export default App;
